import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import { IIntegrationsState } from '../../redux/integrations/integrations.types';
import { ICompanyState } from '../../redux/company/company.types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAircallUsers, getSmartmovingOpportunity } from '../../redux/integrations/integrations.redux';
import { useParams } from 'react-router';
import { Descriptions } from 'antd';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { formatDate, getSmartmovingOpportunityStatus, getSmartmovingPhoneType } from '../../common';
import CopyItem from '../../components/CopyItem/CopyItem';
import { Typography } from '../../components/Typography/Typography';
import AccountManagerSelect from '../../components/AccountManagerSelect/AccountManagerSelect';

export default function SmartmovingOpportunityPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const { activeSmartmovingOpportunity, isSmartmovingOpportunityLoading } = useSelector(
    (state: { integrations: IIntegrationsState }) => state.integrations,
  );
  const { id } = useParams<{ id: string }>();
  const hasContacts = !!activeSmartmovingOpportunity?.contacts?.length;
  const hasJobs = !!activeSmartmovingOpportunity?.jobs?.length;

  useEffect(() => {
    if (!companyId || !id) return;
    dispatch(getSmartmovingOpportunity(id));
    dispatch(getAircallUsers(companyId));
  }, [companyId, id]);

  return (
    <Layout>
      <PageContent hasBack title={t('smartmovingOpportunity.title')}>
        {activeSmartmovingOpportunity && (
          <SC.Container>
            <SC.Column>
              <>
                <Typography variant="h3">{t('smartmovingOpportunity.details')}</Typography>
                <div>
                  <Descriptions bordered column={1} styles={{ label: { width: '50%' }, content: { width: '50%' } }}>
                    <Descriptions.Item label={t('smartmovingOpportunity.opportunityId')}>
                      <CopyItem value={activeSmartmovingOpportunity.opportunityId} />
                    </Descriptions.Item>
                    <Descriptions.Item label={t('smartmovingOpportunity.opportunityStatus')}>
                      {getSmartmovingOpportunityStatus(activeSmartmovingOpportunity.opportunityStatus)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('smartmovingOpportunity.customerName')}>
                      {activeSmartmovingOpportunity.customerName ?? 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('smartmovingOpportunity.customerId')}>
                      <CopyItem value={activeSmartmovingOpportunity.customerId} />
                    </Descriptions.Item>
                    <Descriptions.Item label={t('smartmovingOpportunity.moveSize')}>
                      {activeSmartmovingOpportunity.moveSize ?? 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('smartmovingOpportunity.salesPerson')}>
                      {activeSmartmovingOpportunity.salesPerson ?? 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('smartmovingOpportunity.accountManager')}>
                      <SC.SelectWrapper>
                        <AccountManagerSelect opportunityId={id!} defaultValue={activeSmartmovingOpportunity.accountManager} />
                      </SC.SelectWrapper>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('smartmovingOpportunity.salesPersonId')}>
                      {activeSmartmovingOpportunity.salesPersonId ?? 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('smartmovingOpportunity.bookedDate')}>
                      {activeSmartmovingOpportunity.bookedDate &&
                        formatDate(activeSmartmovingOpportunity.bookedDate, 'humanReadableWithTime')}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('smartmovingOpportunity.serviceDate')}>
                      {activeSmartmovingOpportunity.serviceDate &&
                        formatDate(activeSmartmovingOpportunity.serviceDate, 'humanReadableWithTime')}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('smartmovingOpportunity.createdAt')}>
                      {activeSmartmovingOpportunity.createdAt &&
                        formatDate(activeSmartmovingOpportunity.createdAt, 'humanReadableWithTime')}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('smartmovingOpportunity.updatedAt')}>
                      {activeSmartmovingOpportunity.updatedAt &&
                        formatDate(activeSmartmovingOpportunity.updatedAt, 'humanReadableWithTime')}
                    </Descriptions.Item>
                  </Descriptions>
                </div>

                <div>
                  <Typography variant="h3">{t('smartmovingOpportunity.contacts')}</Typography>
                  {hasContacts ? (
                    activeSmartmovingOpportunity.contacts?.map((contact, index) => (
                      <Descriptions
                        key={JSON.stringify(contact)}
                        bordered
                        column={1}
                        styles={{ label: { width: '50%' }, content: { width: '50%' } }}
                      >
                        {Object.entries(contact).map(([key, value]) => (
                          <Descriptions.Item key={key} label={key}>
                            {key === 'phoneType' ? getSmartmovingPhoneType(value) : String(value ?? 'N/A')}
                          </Descriptions.Item>
                        ))}
                      </Descriptions>
                    ))
                  ) : (
                    <EmptyContent icon="clipboard-list" title={t('smartmovingOpportunity.noContacts')} />
                  )}
                </div>
              </>
            </SC.Column>

            <SC.Column>
              <Typography variant="h3">{t('smartmovingOpportunity.jobs')}</Typography>
              {hasJobs ? (
                activeSmartmovingOpportunity?.jobs?.map((job, index) => (
                  <Descriptions key={job.id} bordered column={1} styles={{ label: { width: '50%' }, content: { width: '50%' } }}>
                    {Object.entries(job).map(([key, value]) => (
                      <Descriptions.Item key={key} label={key}>
                        {String(value ?? 'N/A')}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                ))
              ) : (
                <EmptyContent icon="clipboard-list" title={t('smartmovingOpportunity.noJobs')} />
              )}
            </SC.Column>
          </SC.Container>
        )}
      </PageContent>
    </Layout>
  );
}
