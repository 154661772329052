import { useEffect, useRef, useState } from 'react';
import { Table as AntTable, DatePicker, TableColumnType, TablePaginationConfig } from 'antd';
import CopyItem from '../CopyItem/CopyItem';
import { formatDate } from '../../common';
import * as SC from './styles';
import SearchInput from '../SearchInput/SearchInput';
import Card from '../Card/Card';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { CalendarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TDateFormat } from '../../common/types';

export type TableColumnCustom<T> = TableColumnType<T> & {
  visible?: boolean;
  canCopy?: boolean;
  isRangePicker?: boolean;
  isDate?: boolean;
  dateFormat?: TDateFormat;
};
export type TableChangeOptions = {
  page?: number;
  pageSize?: number;
  search?: string;
  sortBy?: string;
  sortDesc?: boolean;
  filters?: Record<string, FilterValue | null>;
};

interface TableProps {
  columns: TableColumnCustom<any>[];
  data: { data: any[]; count: number };
  loading: boolean;
  currentPage: number;
  pageSize?: number;
  rowKey?: string;
  idName?: string;
  size?: SizeType;
  onChange: (options: TableChangeOptions) => void;
  onClickRow?: (record: any) => void;
}

export default function Table({
  columns,
  data,
  loading,
  currentPage,
  pageSize = 20,
  rowKey = 'id',
  idName = 'id',
  size,
  onChange,
  onClickRow,
}: TableProps) {
  const { t } = useTranslation();
  const tableRef = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number | null>(null);
  // used for calendar filter
  const [calendarFilters, setCalendarFilters] = useState<Record<string, FilterValue | null>>({});

  columns = columns
    .filter(column => column.visible !== false)
    .map(item => {
      let updatedItem = item;

      if (item.canCopy) {
        updatedItem = { ...item, render: data => <CopyItem value={data} /> };
      }

      if (item.isDate) {
        updatedItem = { ...item, render: data => (data ? formatDate(data, item.dateFormat ?? 'humanReadable') : '') };
      }

      if (item.isRangePicker) {
        updatedItem = {
          ...updatedItem,
          filterDropdown: () => (
            <DatePicker.RangePicker
              presets={[
                { label: t('table.filterCalendar-today'), value: [dayjs().startOf('day'), dayjs().endOf('day')] },
                {
                  label: t('table.filterCalendar-yesterday'),
                  value: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')],
                },
                { label: t('table.filterCalendar-thisWeek'), value: [dayjs().startOf('week'), dayjs().endOf('week')] },
                {
                  label: t('table.filterCalendar-lastWeek'),
                  value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
                },
                { label: t('table.filterCalendar-thisMonth'), value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                {
                  label: t('table.filterCalendar-lastMonth'),
                  value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                },
              ]}
              showTime
              onChange={dates => {
                if (dates) {
                  const from = dates[0] ? dates[0].toISOString() : null;
                  const to = dates[1] ? dates[1].toISOString() : null;
                  const filters: any = {
                    [`${item.key}From`]: from,
                    [`${item.key}To`]: to,
                  };
                  onChange({ filters });
                  setCalendarFilters(prev => ({ ...prev, ...filters }));
                } else {
                  const filters: any = {
                    [`${item.key}From`]: undefined,
                    [`${item.key}To`]: undefined,
                  };
                  onChange({ filters });
                  setCalendarFilters(prev => ({ ...prev, ...filters }));
                }
              }}
            />
          ),
          filtered: !!calendarFilters[`${item.key}From`],
          filterIcon: filtered => <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        };
      }

      return updatedItem;
    });

  function handleTableChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | any,
  ) {
    onChange({
      page: pagination.current,
      filters,
      ...(sorter ? { sortBy: sorter.field, sortDesc: sorter.order === 'descend' } : {}),
    });
  }

  function handleSearch(search: string) {
    onChange({ search, page: 1 });
  }

  useEffect(() => {
    const calculateTableHeight = () => {
      if (tableRef.current) {
        const tableElement = tableRef.current;
        const clientHeight = tableElement.clientHeight;
        const headerHeight = 60; // Assuming a fixed header height for the table
        const paginationHeight = 64; // Assuming a fixed height for pagination controls
        const calculatedHeight = clientHeight - headerHeight - paginationHeight;
        setTableHeight(calculatedHeight);
      }
    };

    window.addEventListener('resize', calculateTableHeight);
    calculateTableHeight();

    return () => {
      window.removeEventListener('resize', calculateTableHeight);
    };
  }, []);

  return (
    <Card isFullHeight>
      <SC.TableContent>
        <SearchInput onSearch={handleSearch} />
        <div ref={tableRef} style={{ flexGrow: 1 }}>
          {tableHeight !== null && (
            <AntTable
              size={size}
              columns={columns}
              dataSource={data.data}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: data.count,
                showSizeChanger: false,
                showTotal: total => `${t('table.total')} ${total}`,
              }}
              style={{ display: 'block' }}
              scroll={{ y: tableHeight, x: 'max-content' }}
              onChange={handleTableChange}
              rowKey={rowKey}
              onRow={(record, i) => {
                return {
                  style: { cursor: 'pointer' },
                  onClick: () => {
                    onClickRow?.(record[idName]);
                  },
                };
              }}
            />
          )}
        </div>
      </SC.TableContent>
    </Card>
  );
}
